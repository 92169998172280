'use client'

// NOTE: Error pages have to be client components as defined here:
// https://nextjs.org/docs/app/building-your-application/routing/error-handling

import CustomErrorPage from '~/components/pages/CustomError/CustomError'
import logger from '~/lib/logger'

export default function Error({ error }: { error: Error & { digest?: string }; reset: () => void }) {
	logger.error('ErrorBoundary: Unrecoverable error detected', error)
	return <CustomErrorPage statusCode={500} />
}
